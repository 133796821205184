<script setup>
import { ref, onMounted } from 'vue';
import { getOrCreateDataObject } from 'o365-dataobject';
import Loader from 'sja.vue.libraries.charts.Loader.vue';

let series = ref([]);
let drilldownSeries = ref([]);
let categories = ref([]);
let loaded = ref(false);

const dsReasonsChart = getOrCreateDataObject({
    id: 'dsReasonsChart',
    viewName: 'aviw_SJA_SJAReasons',
    maxRecords: -1,
    whereClause: ``,
    distinctRows: false,
    selectFirstRowOnLoad: true,
    fields:
        [
            { name: "ReasonType" },
            { name: "OrgUnitIdPath" },
            { name: "OrgUnit" },
        ]
});

dsReasonsChart.enableContextFilter(); 

dsReasonsChart.on('DataLoaded', async (data, options) => {
    if (dsReasonsChart.data.length > 0) {
        categories.value = [
            ...new Set(dsReasonsChart.data.map((element) => element.ReasonType)),
        ];

        categories.value.forEach((value, index) => {
            const totalReasonTypeCount = dsReasonsChart.data.filter(row => row.ReasonType === value).length;
            const percentage = totalReasonTypeCount / dsReasonsChart.data.length * 100;

            series.value.push({ name: value, y: percentage, drilldown: `OrgUnit${index}` });

            let orgUnitCounts = {};
            dsReasonsChart.data.filter(row => row.ReasonType === value).forEach(row => {
                orgUnitCounts[row.OrgUnit] = (orgUnitCounts[row.OrgUnit] || 0) + 1;
            });

            const totalCount = Object.values(orgUnitCounts).reduce((acc, curr) => acc + curr, 0);
            const orgUnitPercentages = {};
            Object.entries(orgUnitCounts).forEach(([orgUnit, count]) => {
                orgUnitPercentages[orgUnit] = (count / totalCount) * 100;
            });

            const drilldownSeriesData = Object.entries(orgUnitPercentages).map(([orgUnit, percentage]) => [orgUnit, percentage]);

            drilldownSeries.value.push({ id: `OrgUnit${index}`, data: drilldownSeriesData });
        });

        await createChart()
    } else {
        series = ref([]);
        drilldownSeries = ref([]);
        categories = ref([]); 
        await createChart()
    }
    loaded.value = true
});

onMounted(async () => {
    await dsReasonsChart.load();
})

function createChart() {
    const mergedOptions = {
        chart: {
            type: 'pie',
        },

        title: {
            text: $t(`Summary of SJA reasons`)
        },
        plotOptions: {
            series: {
                allowPointSelect: true,
                cursor: 'pointer',
                drilldown: true
            }
        },
        tooltip: {
            headerFormat: '<span style="font-size:11px; text-decoration:none">{series.name}</span><br>',
            pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>'
        },
        series: [{
            name: 'Reason',
            colorByPoint: true,
            data: series.value
        }],
        drilldown: {
            series: drilldownSeries.value
        }
    };

    Highcharts.chart('reasonsChartContainer', mergedOptions);
};
</script>

<template>
    <div id="reasonsChartContainer" class="p-2" style="width: 100%">
        <Loader :loaded="loaded"></Loader>
    </div>
</template>
